import PropTypes from 'prop-types';
import withOutcome from 'sportsbook-with-outcome';
import classnames from 'classnames';

const outcomeButtonClassName = (selected) => classnames('upsell-card-button bvs-button-sport', {
  active: selected,
});

const Outcome = ({
  outcome, selected, toggleBetslip, sportId,
}) => (
  <div className={`upsell-card bvs-card is-sport is-sport-${sportId}`} onClick={toggleBetslip}>
    <div className="upsell-card-text">
      {
        (outcome.legDescs && outcome.legDescs.map((desc) => <p key={desc}>{desc}</p>))
          || <p>{outcome.description}</p>
      }
    </div>
    <div className={outcomeButtonClassName(selected)}>{outcome.formattedPrice}</div>
  </div>
);

Outcome.propTypes = {
  outcome: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default withOutcome(Outcome);
